import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Importing Modal

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
        <section className="section bg-home home-half" id="home" data-image-src="images/home-banner.jpg"> 
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col
                lg={{ size: 8, offset: 2 }}
                className="text-white text-center"
              >
                <h4 className="home-small-title">Miss Haley’s Programs for Kids</h4>
                <h1 className="home-title">
                  We love make things amazing and simple
                </h1>
                <p className="pt-3 home-desc mx-auto">
                Hi there! I’m Miss Haley and my passion is creating out of the box educational programs for kiddos like yours or ones you know! My goal is to provide fun and creative hands-on activities to help children’s comprehension and retention of a variety topics. Check out the programs I currently offer: (big arrow to the one current program listed.)
                </p>
                <p className="play-shadow mt-4">
                  {/* <Link
                    onClick={this.callModal}
                    to="/#"
                    className="play-btn video-play-icon"
                  >
                    <i className="mdi mdi-play text-center"></i>
                  </Link> */}
                </p>
              </Col>
              {/* Render ModalSection Component for Modal */}
              {/* <ModalSection ref="child" channel="vimeo" videoId="99025203" /> */}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
