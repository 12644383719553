import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

//Import Section Title

//Import Service Box
// import ServiceBox from "./service-box";
// import { Link } from "react-router-dom/cjs/react-router-dom";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isContentVisible: false,
    };
  }

  toggleContentVisibility = () => {
    this.setState(prevState => ({
      isContentVisible: !prevState.isContentVisible,
    }));
  };

  state = {
    services: [
      {
        title: "Arrival & Welcome & Introduction to the Afternoon",
        icon: "pe-7s-diamond",
        description:
          "Some quick example text to build on the card title and make up the bulk of the card's content. Moltin gives you the platform.",
      },
      {
        title: "Snack",
        icon: "pe-7s-display2",
        description:
          "Credibly brand standards compliant users without extensible services. Anibh euismod tincidunt ut laoreet Ipsum passage.",
      },
      {
        title: "Mini Lesson & Project Overview",
        icon: "pe-7s-piggy",
        description:
          "Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean necessary regelialia.",
      },
      {
        title: "Project",
        icon: "pe-7s-science",
        description:
          "It is a paradisematic country, in which roasted parts of sentences fly into your mouth leave for the far World.",
      },
      {
        title: "Clean Up ",
        icon: "pe-7s-news-paper",
        description:
          "Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic.",
      },
      {
        title: "Group Game, Story, Dance, & Take Aways",
        icon: "pe-7s-plane",
        description:
          "Question Marks and devious Semikoli, but the Little Blind Text didn’t listen. She packed her seven versalia.",
      },
      {
        title: "Pick Up",
        icon: "pe-7s-arc",
        description:
          "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.",
      },
      {
        title: "Snack",
        icon: "pe-7s-tools",
        description:
          "Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur.",
      },
      {
        title: "3.	Mini Lesson & Project Overview",
        icon: "pe-7s-timer",
        description:
          "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.",
      },
    ],
  };
  render() {
    const { isContentVisible } = this.state;
    return (
      <React.Fragment>
        <section className="section bg-light" id="services">
          <Container>
            {/* Render section title */}

            <Row>
              <Col lg={{ size: 8, offset: 2 }}>
                <h1 className="section-title text-center">Miss Haley’s After School Program with HCCA</h1>
                <div className="section-title-border mt-3"></div>
                <p className="section-subtitle text-muted text-center pt-4 font-secondary" style={{fontSize:'30px'}}>
                  We would love for your child to join us every Tuesday and Thursday from 3pm to 5pm* for an enriching after school program. We will be expanding upon the Science and History curriculum that is offered at HCCA. (Click for more information)
                </p>
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <button className="btn fw-bold fs-2" style={{ color: "rgb(240,32,106)" }} onClick={this.toggleContentVisibility}>
                {isContentVisible ? 'Hide Content' : 'More Information'}
              </button>
            </div>
            <div className="col-lg-8 col-12 mx-auto d-flex justify-content-center">
              {isContentVisible && (
                <div>
                  <p className="section-subtitle fs-3 text-muted text-center pt-4 font-secondary">
                    Holy City Christian Academy parent(s), we would love for your child to join us every Tuesday and Thursday from 3pm to 5pm* for an enriching after school program. We will be expanding upon the Science and History curriculum that is offered at HCCA. You can expect your child to always have a hands-on activity that emphasis the curriculums topic(s) for the month. Why? We believe a little extra practice helps knowledge stick!
                  </p>
                  <h5 className="fw-bold mt-4 section-subtitle fs-2">Time/Date</h5>
                  <p className="fs-3">Every Tuesday & Thursday  /3pm to 5pm* (optional 5:30pm pick up for additional cost)</p>
                  <h5 className="fw-bold mt-4 section-subtitle fs-2">For whom</h5>
                  <p className="section-subtitle text-muted fs-3"> K to 4th Grade </p>
                  <h5 className="fw-bold section-subtitle fs-2">Location</h5>
                  <p className="fs-3">Holy City Christian Academy’s Campus </p>
                  <h5 className="fw-bold section-subtitle fs-2">Cost</h5>
                  <p className="fs-3"> $70 per week or $250 per month</p>
                  <h5 className="fw-bold section-subtitle fs-2">Limited Time Deal</h5>
                  <p className="fs-3">Register for the entire month of September by September 1st, receive $25 off. </p>

                  <h5 className="fw-bold section-subtitle fs-2">Note</h5>
                  <p >Must register by Friday of the previous week by 5pm for the following week’s program.
                    September’s Theme is Volcanos, Rocks, Continents, Map Comprehension, and Compass Skills.
                  </p>
                  <p className="fw-bold section-subtitle">
                    Children must bring their own snack that is peanut and pine nut free! (No exception)
                  </p>

                  <h5 className="fw-bold section-subtitle fs-3 fw-bold">Daily Program Overview</h5>

                  <ul className="fs-4 fw-bold">
                    <li className="features-item-list1122">Arrival & Welcome & Introduction to the Afternoon</li>
                    <li className="features-item-list1122">Snack</li>
                    <li className="features-item-list1122">Mini Lesson & Project Overview</li>
                    <li className="features-item-list1122">Project</li>
                    <li className="features-item-list1122">Clean Up</li>
                    <li className="features-item-list1122">Group Game, Story, Dance, & Take Aways</li>
                    <li className="features-item-list1122">Pick Up</li>
                  </ul>
                  <p className="fw-bold section-subtitle">Register for the entire month of September by September 1st, receive $25 off. </p>


                </div>
              )}
            </div>

          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
