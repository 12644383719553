import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import toast, { Toaster } from 'react-hot-toast';
import ReactStars from 'react-stars'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";
import Slider from "react-slick";

import SectionTitle from "../common/section-title";

import axios from "axios";
import { header } from "../../Config";

const Testimonials = () => {
  const imageref = useRef(null)
  const [dataApi, setDataApi] = useState([]);
  // const [star, setStar] = useState(5);

  // const onChange = (nextValue) => {
  //   setStar(nextValue)
  // }


  const [data, setData] = useState({
    name: '',
    rating: '',
    image: '',
    reviews: ''
  });
  const [rating, setrating] = useState(3);
  const [imagedata, setImagedata] = useState(null);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setImagedata(imageFile)
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleInputStar = (event) => {
    setrating(event.target.value)
  };

  const postData = async () => {
    try {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('stars', rating);
      formData.append('title', "shahbaz");
      formData.append('review', data.reviews);

      formData.append('attachment', imagedata);

      const response = await axios.post("https://misshaileyapi.dev-sh.xyz/api/review", formData, header)
      // if(response.status)
      // alert(response.data)
      toast.success(response.data.message, {
        style: {
          fontSize: "28px" 
        }
      })
      // console.log(response.data.message, "respdata");
      setData({
        name: '',
        reviews: ''
      });
      setImagedata(null)
      imageref.current.value =null
    }
    catch (error) {
      console.log(error);
      setData({
        name: '',
        reviews: ''
      });
    }
  

  }
  const handleSubmit = (event) => {
    event.preventDefault();
    return postData();
  };

  const getData = async () => {
    try {
      const response = await axios.get("https://misshaileyapi.dev-sh.xyz/api/reviews")
      // toast.success('Successfully toasted!')
      console.log(response.data.data, "respdataGetData");
      setDataApi(response?.data?.data)
    }
    catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    return getData();
  }, [])


  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  // const testimoni = [
  //   {
  //     id: 1,
  //     image: "assets/images/testimonials/user-1.jpg",
  //     name: "DENNIS WILLIAMS",
  //     cmpName: "Charleston",
  //     message:
  //       "“I feel confident imposing change on myself. It's a lot more fun progressing than looking back. That's why I need to throw curve balls.”",
  //   },
  //   {
  //     id: 2,
  //     image: "assets/images/testimonials/user-2.jpg",
  //     name: "LAURIE BELL",
  //     cmpName: "Worcester",
  //     message:
  //       "“Our task must be to free ourselves by widening our circle of compassion to embrace all living creatures and the whole of nature and its beauty.”",
  //   },
  //   {
  //     id: 3,
  //     image: "assets/images/testimonials/user-3.jpg",
  //     name: "HOWARD KELLEY",
  //     cmpName: "Lynchburg",
  //     message:
  //       "“I've learned that people will forget what you said, people will forget what you did, but people will never forget how you made them feel.”",
  //   },
  // ];

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />




      <section className="section" id="testi">
        <Container>
          {/* Render section title */}
          <SectionTitle title="We love your kids & would love your feedback! " />
          
          <Row className="mt-2">
            <Slider {...settings}>
              {dataApi?.map((testimonial, key) => (
                // <Col lg="4">
                  <div className="testimonial-box mt-4">
                    <div className="testimonial-decs p-4 h-25 text-truncate">
                      <div className="testi-icon">
                        <i className="mdi mdi-format-quote-open display-2"></i>
                      </div>
                      <img
                        src={testimonial.attachment}
                        alt=""
                        className="img-fluid mx-auto d-block img-thumbnail rounded-circle mb-4"
                      />

                      <div className="p-1">
                        <p className="text-center text-uppercase mb-3" style={{ color: "rgb(240,32,106)",fontSize:"15px", fontWeight:'bold'}}>
                          {testimonial.name} -{" "}
                          {/* <span className="text-muted text-capitalize">
                          {testimonial.name}
                        </span> */}
                        </p>
                        <div className="d-flex justify-content-center">
                          <ReactStars
                            // onChange={onChange}
                            value={testimonial.stars}
                            edit={true}
                            activeColors={["red", "orange", "#FFCE00", "#9177FF", "#8568FC",]}
                          />
                        </div>
                        <p className="text-muted text-center mb-0 text-truncate p-1">
                          {testimonial.review}
                        </p>

                      </div>
                    </div>
                  </div>
                // </Col>
              ))}
            </Slider>
       
            
            {/* <TestimonialBox /> */}


          </Row>

          <Row>
            <Col lg="4" style={{ margin: "50px auto", textAlign: "center" }}>

              <button type="button" className="btn btn-primary fs-3 fw-bold" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Give us your feedback
              </button>

              <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <p class="" id="exampleModalLabel">Your Reviews</p>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                      <form id="contact-form" onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-12">
                            <input
                              type="text"
                              className="form-group mt-3 w-100 p-2"
                              name="name"
                              placeholder="Your name*"
                              value={data.name}
                              onChange={handleInputChange}
                            />

                          </div>
                          <div className="col-lg-12 d-flex my-2 fs-3">
                            <span className="fs-2 fw-bold" style={{color:"#F0206A"}}>Give your feedback
                          <ReactStars
                            onChange={()=>handleInputStar}
                            // style={{fontSize:"50px"}}
                            size={30}
                            class="fs-3"
                            value={rating}
                            edit={true}
                            activeColors={["red", "orange", "#FFCE00", "#9177FF", "#8568FC",]}
                          />
                          </span>
                            {/* <input
                              type="number"
                              className="form-group mt-3 w-100 p-2"
                              name="rating"
                              placeholder="Your rating*"
                              value={rating}
                              onChange={handleInputStar}
                            /> */}

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 text-start my-2">
                            <input
                              type="file"
                              className="form-group mt-2"
                              name="image"
                              accept="image/*"
                              ref={imageref}
                              onChange={handleImageChange}
                            />
                          </div>

                          <div className="col-lg-12">
                            <textarea
                              className="form-group mt-3 w-100 p-2"
                              name="reviews"
                              placeholder="Your Reviews*"
                              value={data.reviews}
                              onChange={handleInputChange}
                            />

                          </div>

                        </div>
                        <div className="text-end">
                          <button className="submitBnt text-end btn btn-primary fs-5 fw-bold" type="submit" data-bs-dismiss="modal">Submit Reviews</button>
                        </div>
                      </form>
                    </div>
                    {/* <div class="modal-footer">
                    data-bs-dismiss="modal"
                      <button className="submitBnt text-end btn btn-primary" type="submit" >Submit Reviews</button>
                    </div> */}
                  </div>
                </div>
              </div>

            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Testimonials;
