import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class WebsiteDescription extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-web-desc">
          <div className="bg-overlay"></div>
          <Container>
            <Row>
              <Col lg="12" className="text-center">
                <h2 className="home-title">Hi there, we are always accepting donations</h2>
                <p className="pt-3 home-desc mx-auto">
                  Hi there, we are always accepting donations. No silly, not $$ but any old or new crafting supplies, toys, paper towel rolls, boxes, books, games, or educational materials. Not sure if it falls into that category? Just ask! Either way, we would be blessed to take any items off your hands! Please reach out at
                </p>
                {/* <a >Send Email</a> */}
                <a
                  href={`mailto:shahbazashiq88@gmail.com`}
                  className="btn mt-5 waves-effect waves-light text-white"
                  style={{ backgroundColor: "rgb(240,32,106)", fontSize:"25px", fontWeight:"bold" }}
                >
                  Donation
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default WebsiteDescription;
