import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
// import { Link } from "react-router-dom";
// import { Col } from "reactstrap";

class BlogBox extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <Col lg="4">
          <div className="blog-box mt-4">
            <img
              src={this.props.blog.image}
              className="img-fluid rounded"
              alt=""
            />
            <div>
              <h5 className="mt-4 text-muted">{this.props.blog.topic}</h5>
              <h4 className="mt-3">
                <Link to={this.props.blog.link} className="blog-title">
                  {" "}
                  {this.props.blog.title}{" "}
                </Link>
              </h4>
              <p className="text-muted">{this.props.blog.description}</p>
              <div className="mt-3">
                <Link to={this.props.blog.link} className="read-btn">
                  Read More <i className="mdi mdi-arrow-right"></i>
                </Link>
              </div>
            </div>

          </div>
        </Col> */}

        <div className="col-lg-4 col-md-6 col-12 mx-auto">
        <AvForm>
                    <Row>
                      <Col lg="12">
                        <AvField
                          type="text"
                          className="form-group mt-2"
                          name="name"
                          id="name"
                          placeholder="Your name*"
                          required
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your name",
                            },
                          }}
                        />
                      </Col>
                      <Col lg="12">
                        <AvField
                          type="text"
                          className="form-group mt-2"
                          name="contact"
                          id="contact"
                          placeholder="Phone Number*"
                          required
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your phone number",
                            },
                          }}
                        />
                      </Col>
                     
                      <Col lg="12">
                        <AvField
                          type="email"
                          className="form-group mt-2"
                          name="email"
                          id="email"
                          placeholder="Your email*"
                          required
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your email",
                            },
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="12">
                        <AvField
                          type="text"
                          className="form-group mt-2"
                          name="Inquiry"
                          id="subject"
                          placeholder="Your Subject.."
                          required
                          errorMessage=""
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter your topic",
                            },
                            pattern: {
                              value: "^[A-Za-z0-9]+$",
                              errorMessage: "topic is invalid!",
                            },
                          }}
                        />
                      </Col> */}
                    </Row>
                    <Row>
                      <Col lg="12">
                        <div className="form-group mt-2">
                          <textarea
                            name="Inquiry"
                            id="Inquiry"
                            rows="4"
                            className="form-control"
                            placeholder="Your Inquiry..."
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="text-end">
                        <Button className="submitBnt btn btn-primary">
                          Send Message
                        </Button>
                        <div id="simple-msg"></div>
                      </Col>
                    </Row>
                  </AvForm>
          {/* <form className="mx-auto">
            <label className="d-block">Name: </label>
            <input className="w-100 p-2"/>
            <label className="d-block mt-3">Phone Number: </label>
            <input className="w-100 p-2"/>
            <label className="d-block mt-3">Email: </label>
            <input className="w-100 p-2"/>
            <label className="d-block mt-3">Inquiry: </label>
            <textarea className="w-100 p-2" style={{height:'100px'}}></textarea>
          </form> */}
        </div>
       
      </React.Fragment>
    );
  }
}

export default BlogBox;
