import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import toast, { Toaster } from 'react-hot-toast';
// import { AvForm, AvField } from "availity-reactstrap-validation";

// Import Section Title
import SectionTitle from "../common/section-title";
import axios from "axios";
import { header } from "../../Config";
// import axios from "axios";

const ContactUs = () => {



  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    subject: '',
    comments: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };


  const apiData = async () => {
    if (formData.name === '' || formData.email === '' || formData.contact === ''|| formData.subject === ''|| formData.comments === '') {
      toast.error("please fill all the field")
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone', formData.contact);
        formDataToSend.append('subject', formData.subject);
        formDataToSend.append('message', formData.comments);
        const response = await axios.post("https://misshaileyapi.dev-sh.xyz/api/contact", formDataToSend, header)
        setFormData({
          name: '',
          email: '',
          contact: '',
          subject: '',
          comments: ''
        });
        toast.success(response.data.message, {
          style: {
            fontSize: "28px" 
          }
        })
      }
      catch (error) {
        console.log(error);
        setFormData({
          name: '',
          email: '',
          contact: '',
          subject: '',
          comments: ''
        });
      }
    }



  }

  const handleSubmit = (event) => {
    event.preventDefault();
    return apiData();
   
  };



  return (
    <>
     <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <section className="section" id="contact">
        <Container>
          {/* Render section title */}
          <SectionTitle
            title="Get In Touch"
            description="We thrive when coming up with innovative ideas but also understand that a smart concept should be supported with measurable results."
          />

          <Row>
            <Col lg="4">
              <div className="mt-4 pt-4">
                <p className="mt-4">
                  <span className="h5 fw-bold" style={{ color: "#F0206A" }}>
                    Office Address:
                  </span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    Holy City Christian Academy’s Campus
                  </span>
                </p>

                <p className="mt-4">
                  <span className="h5 fw-bold" style={{ color: "#F0206A" }}>
                    Working Hours:
                  </span>
                  <br />{" "}
                  <span className="text-muted d-block mt-2">
                    3:00PM To 5:00PM
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="6">
              <div className="custom-form mt-4 pt-4">
                <div id="message"></div>

                <form id="contact-form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <input
                        type="text"
                        className="form-group mt-3 w-100 p-2"
                        name="name"
                        placeholder="Your name*"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                     
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <input
                        type="email"
                        className="form-group mt-3 w-100 p-2"
                        name="email"
                        placeholder="Your email*"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                     
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-6 col-12">
                      <input
                        type="text"
                        className="form-group mt-3 w-100 p-2"
                        name="contact"
                        placeholder="Your Contact no.*"
                        value={formData.contact}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-lg-12 col-md-6 col-12">
                      <input
                        type="text"
                        className="form-group mt-3 w-100 p-2"
                        name="subject"
                        placeholder="Your Subject*"
                        value={formData.subject}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <textarea
                        className="form-group mt-3 w-100 p-2"
                        name="comments"
                        placeholder="Your Comments*"
                        value={formData.comments}
                        onChange={handleInputChange}
                      />
                     
                    </div>
                    <div className="d-flex justify-content-center my-2">
                      <button className="submitBnt text-end btn btn-primary fs-5 fw-bold" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>

                {/* <AvForm name="contact-form" id="contact-form">
                  <Row>
                    <Col lg="6">
                      <AvField
                        type="text"
                        className="form-group mt-2"
                        name="name"
                        id="name"
                        placeholder="Your name*"
                        required
                        errorMessage=""
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter your name",
                          },
                        }}
                      />
                    </Col>
                    <Col lg="6">
                      <AvField
                        type="email"
                        className="form-group mt-2"
                        name="email"
                        id="email"
                        placeholder="Your email*"
                        required
                        errorMessage=""
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter your email",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <AvField
                        type="text"
                        className="form-group mt-2"
                        name="Phone no."
                        id="Phone no."
                        placeholder="Your contact.."
                        required
                        errorMessage=""
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter your phone no.",
                          },
                          pattern: {
                            value: "^[A-Za-z0-9]+$",
                            errorMessage: "topic is invalid!",
                          },
                        }}
                      />
                    </Col>
                    <Col lg="6">
                      <AvField
                        type="text"
                        className="form-group mt-2"
                        name="subject"
                        id="subject"
                        placeholder="Your Subject.."
                        required
                        errorMessage=""
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Please enter your topic",
                          },
                          pattern: {
                            value: "^[A-Za-z0-9]+$",
                            errorMessage: "topic is invalid!",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <div className="form-group mt-2">
                        <textarea
                          name="comments"
                          id="comments"
                          rows="4"
                          className="form-control"
                          placeholder="Your message..."
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="text-end">
                      <Button className="submitBnt btn btn-primary">
                        Send Message
                      </Button>
                      <div id="simple-msg"></div>
                    </Col>
                  </Row>
                </AvForm> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContactUs;
