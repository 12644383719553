import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <Row className="align-items-center">
              <Col lg="5" className="order-2 order-lg-1">
                <div className="features-box mt-5 mt-lg-0">
                  <h3 style={{color:"#F0206A", fontSize:'50px'}}>
                    A digital web design studio creating modern & engaging
                    online
                  </h3>
                  {/* <p className="text-muted web-desc">
                  Hi there! I’m Miss Haley and my passion is creating out of the box educational programs for kiddos like yours or ones you know! My goal is to provide fun and creative hands-on activities to help children’s comprehension and retention of a variety topics.
                  </p> */}
                  <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className="">Hi there! I’m Miss Haley and my passion is creating out of the box educational programs for kiddos like yours or ones you know! My goal is to provide fun and creative hands-on activities to help children’s comprehension and retention of a variety topics.</li>
                    <li className="">As I was growing up, I struggled to retain information taught in school that was not catered towards my style of learning, hands on learning. I firmly believe if children are taught by and through examples, then knowledge will stick. </li>
                    <li className="">
                    Our teachers do not always have the time or resources to provide this extra step. Especially when they are focusing on “common core,” such as math, reading, and language. That is why I am offering a variety of extracurricular programs that enhance the topics children are learning in and out of school. These programs deep dive into topics like geography, state history, American history, science, dance, STEM, cooking, travel, map skills, team work, at home tasks, etc. 
                    </li>
                    <li className="">Now, let’s get that information to STICK! </li>
                  </ul>
                  <Link
                    to="#"
                    style={{fontSize:"20px"}}
                    className="btn btn-primary mt-4 waves-effect waves-light fs-5 fw-bold"
                  >
                    Learn More <i className="mdi mdi-arrow-right"></i>
                  </Link>
                </div>
              </Col>
              <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
                <div className="features-img mx-auto me-lg-0">
                  <img
                    src="assets/images/image1122.jpg"
                    // height={'200px'}
                    alt="macbook"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
